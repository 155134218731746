import { FolderCreationResponse, PatchFolderItemType, SelectedFolderItemType } from 'types/folders'

import { RequestService } from 'services/request.service'
import ToastService from './toast.service'

export class FolderService extends RequestService {
  public details = (id?: number | string, queryString: string = '') => {
    let folderId = id
    let params = queryString

    // Allows the client to refetch data without needing to know page specifics
    if (!folderId && typeof window !== 'undefined') {
      folderId = location.pathname.split('/folders/')[1]
      params = location.search
    }

    return super.get(`/api/decks/folders/${folderId}/?${params}`)
  }

  public deleteItems = (items: SelectedFolderItemType[]) => {
    return super.post('/api/decks/folders/deleteItems/', { items })
  }

  public updateItems = (items: PatchFolderItemType[]) => {
    return super.patch('/api/massUpdate/', { items })
  }

  public create = (name: string, isPrivate: boolean, parentFolderId: string | number) => {
    return super.post<FolderCreationResponse>('/api/decks/folders/', {
      name,
      private: isPrivate,
      parentFolder: parentFolderId,
    })
  }
}

const folderService = new FolderService()

export default folderService
