import Link from 'components/elements/Link'
import React, { useEffect, useState } from 'react'
import Icon from 'components/elements/Icon'

import { SubfolderFolderItemType } from 'types/folders'

import FolderService from 'services/folder.service'

import Header from 'components/userPage/Header'
import FadeInOut from 'components/transitions/FadeInOut'

import { gridClass } from 'components/misc/DeckLink'

import styles from './folderList.module.scss'

export const defaultSubFolderResponse: SubfolderFolderItemType[] = new Array(15).fill(undefined)

type Props = {
  folderId: number | string
  className?: string
}

const FolderList = ({ folderId, className = '' }: Props) => {
  const [loading, setLoading] = useState(true)
  const [subFolders, setSubFolders] = useState<SubfolderFolderItemType[]>(defaultSubFolderResponse)

  useEffect(() => {
    setLoading(true)

    FolderService.details()
      .then(res => setSubFolders(res.subfolders))
      .catch(err => {
        console.error(err)

        setSubFolders([])
      })
      .finally(() => setLoading(false))
  }, [folderId])

  return (
    <div className={`${styles.container} ${className}`}>
      <Header main="Deck Folders" secondary="View root folder" href={`/folders/${folderId}`} />
      <div className={gridClass}>
        {subFolders.map((folder, index) => (
          <ListItem key={index} folder={folder} loading={loading} />
        ))}
      </div>
      {!loading && !subFolders?.length && <div className={styles.noFolders}>This user has no public folders</div>}
    </div>
  )
}

export default FolderList

const ListItem = ({ folder, loading }: { folder: SubfolderFolderItemType; loading: boolean }) => (
  <Link href={`/folders/${folder?.id}`} className={`${styles.folderItem} ${loading ? styles.loading : ''}`}>
    <FadeInOut show={!loading}>
      <Icon name="folder" />
      {folder?.name}
    </FadeInOut>
  </Link>
)
